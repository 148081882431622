import { useEffect } from 'react'
import { useAuth } from '../../contexts/auth'
import { pageview } from '../../services/gtag'

const GoogleAnalytics = () => {
  const { user, loadingUser } = useAuth()

  useEffect(() => {
    // register the first page view when come from server
    if (!loadingUser) {
      pageview(window.location.pathname, user ? user.id : undefined)
    }
  }, [loadingUser])

  return null
}

export default GoogleAnalytics
