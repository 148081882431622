import { gql } from 'apollo-boost'
import { initializeApollo } from './apolloClient'

const DO_CHANGE_COUNTRY = gql`
  mutation setCountry($country: String!) {
    setCountry(country: $country)
  }
`
export const changecountry = async country => {
  const apolloClient = initializeApollo()

  return apolloClient.mutate({
    mutation: DO_CHANGE_COUNTRY,
    variables: {
      country,
    },
  })
}
