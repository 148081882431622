import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  getUserToken,
  requestPermission,
  hasUserToken,
  initFirebase,
} from '../utils/firebase.util'
import { ModalNotificationPermission } from '../components/ModalNotificationPermission'
import moment from 'moment'
import { gql } from 'apollo-boost'
import { initializeApollo } from '../services/apolloClient'
import firebase from 'firebase/app'

const lastTryKey = '@CO3D/fcm_last_try'

const SET_TOKEN = gql`
  mutation ($token: String!) {
    setToken(token: $token)
  }
`

const NotificationContext = createContext({
  getUserPermission: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMessage: callback => undefined,
})

export const NotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(0)
  const [errorCode, setErrorCode] = useState()

  useEffect(() => {
    initFirebase()
  }, [])

  const getUserPermission = async () => {
    if (!firebase.messaging.isSupported()) return false

    const tokenExists = await hasUserToken()
    if (tokenExists) return false

    const lastTry = localStorage.getItem(lastTryKey)
    const lastTryDate = moment(lastTry)
    const timeAgo = moment().subtract(30, 'days')
    if (lastTryDate > timeAgo) return false

    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const updateToken = async token => {
    const apolloClient = initializeApollo()

    await apolloClient.mutate({
      mutation: SET_TOKEN,
      variables: {
        token,
      },
    })
  }

  const handleAccept = async () => {
    setLoading(1)
    try {
      let token = await requestPermission()
      setLoading(2)
      if (!token) {
        token = await getUserToken()
      }

      await updateToken(token)
      handleClose()
    } catch (err) {
      if (
        err.code &&
        (err.code === 'messaging/permission-default' ||
          err.code === 'messaging/permission-blocked')
      ) {
        setErrorCode(err.code)
      } else {
        setErrorCode('genericError')
      }
    }
    setLoading(0)
  }

  const handleRefuse = () => {
    const now = new Date()
    localStorage.setItem(lastTryKey, now.toISOString())
    handleClose()
  }

  const onMessage = callback => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging()
      return messaging.onMessage(callback)
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        getUserPermission,
        onMessage,
      }}
    >
      {children}
      <ModalNotificationPermission
        show={show}
        handleClose={handleClose}
        onAccept={handleAccept}
        loadingAccept={loading}
        onRefuse={handleRefuse}
        errorCode={errorCode}
      />
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  return useContext(NotificationContext)
}
