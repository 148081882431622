import { useEffect, useMemo } from 'react'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, Observable } from 'apollo-link'
import { getAccessToken, setAccessToken } from './accessToken'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import Router, { useRouter } from 'next/router'
import { refreshToken } from './auth'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-unfetch'

let apolloClient
let currentLocale

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken()
    if (!token || !token.token) return true
    if (new Date() >= token.expiresIn) return false
    return true
  },
  fetchAccessToken: async () => {
    const result = await refreshToken()
    return result.json()
  },
  handleResponse: (operation, accessTokenField) => response => {
    const now = new Date()
    setAccessToken({
      token: response.accessToken,
      expiresIn: new Date(now.getTime() + response.expiresIn * 1000),
    })
    return {
      [accessTokenField]: response.accessToken,
    }
  },
  handleError: () => {
    setAccessToken(null)
    Router.push('/login')
  },
})

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(operation => {
          const headers = {
            'accept-language': currentLocale,
          }

          const accessToken = getAccessToken()
          if (accessToken && accessToken.token) {
            headers.authorization = `Bearer ${accessToken.token}`
          }

          operation.setContext({
            headers,
          })
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const uploadLink = createUploadLink({
  uri: process.env.NEXT_STATIC_API_URL,
  credentials: 'include',
  fetch,
})

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([refreshTokenLink, requestLink, uploadLink]),
    cache: new InMemoryCache(),
  })
}

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const useApollo = initialState => {
  const { locale } = useRouter()
  useEffect(() => {
    currentLocale = locale
  }, [locale])

  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
