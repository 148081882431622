import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  saveUserPreferredLocale,
  getUserPreferredLocale,
} from '../services/translation'
import { changelang } from '../services/changelang'
import { _getCookie, _setCookie } from '../services/cookie'
import { useTranslation } from '../services/translation'
import { changecountry } from '../services/changecountry'
import { useAuth } from './auth'
import { useRouter } from 'next/router'

const LocalesContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  currency: null,
  preferredLanguage: null,
  country: null,
  changeLanguage: (lng, currency) => undefined,
  updateLanguage: language => undefined,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const LocalesProvider = ({ children }) => {
  const [currency, setCurrency] = useState()
  const [country, setCountry] = useState()
  const [preferredLanguage, setPreferredLanguage] = useState()
  const { user, updateUser } = useAuth()
  const cookieLifetime = 365
  const cookieCurrecyName = 'userCurrency'
  const cookieCountryName = 'userCountry'
  const { lang } = useTranslation()
  const { locale } = useRouter()

  const requestCountry = async () => {
    try {
      const response = await fetch(
        'https://ipinfo.io/json?token=a369551f52a989'
      )
      const jsonResponse = await response.json()
      setCountry(jsonResponse.country)
      _setCookie(cookieCountryName, jsonResponse.country, cookieLifetime)
      return jsonResponse.country
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const loadCountry = async () => {
      if (user) {
        if (!user.country) {
          const countryFound = await requestCountry()
          if (countryFound) {
            changecountry(countryFound)
          }
        } else {
          setCountry(user && user.country)
          _setCookie(cookieCountryName, user && user.country, cookieLifetime)
        }
      } else {
        if (_getCookie(cookieCountryName)) {
          setCountry(_getCookie(cookieCountryName))
        } else {
          requestCountry()
        }
      }
    }
    loadCountry()
  }, [user])

  useEffect(() => {
    if (user) {
      updateLanguage(user.language)
      return
    }
    updateLanguage(getUserPreferredLocale() || locale)
  }, [user])

  useEffect(() => {
    if (user) {
      _setCookie(cookieCurrecyName, user.currency, cookieLifetime)
      setCurrency(user.currency)
      return
    }
    const currencyOnCookie = _getCookie(cookieCurrecyName)
    if (currencyOnCookie) {
      setCurrency(currencyOnCookie)
    } else {
      const newCurrency = lang !== 'pt' ? 'USD' : 'BRL'
      setCurrency(newCurrency)
      _setCookie(cookieCurrecyName, newCurrency, cookieLifetime)
    }
  }, [user])

  const changeLanguage = async (language, currency) => {
    updateLanguage(language)
    setCurrency(currency)
    _setCookie(cookieCurrecyName, currency, cookieLifetime)
    if (user) {
      await changelang(language, currency)
      updateUser({
        language,
        currency,
      })
    }
  }

  const updateLanguage = language => {
    setPreferredLanguage(language)
    saveUserPreferredLocale(language)
  }

  return (
    <LocalesContext.Provider
      value={{
        currency,
        preferredLanguage,
        country,
        changeLanguage,
        updateLanguage,
      }}
    >
      {children}
    </LocalesContext.Provider>
  )
}

export const useLocales = () => {
  return useContext(LocalesContext)
}
