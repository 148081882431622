import React, { createContext, useState, useEffect, useContext } from 'react'

const BalanceContext = createContext({})

const storageKey = '@CO3D/balance'
const storageKeyPending = '@CO3D/balancePending'
const storageKeyChargeback = `${storageKey}Chargeback`
const storageKeyCollection = `${storageKey}Collection`

const isServer = () => typeof window === 'undefined'

export const BalanceProvider = ({ children }) => {
  const [balance, setBalance] = useState(null)
  const [balancePending, setbalancePending] = useState(null)
  const [balanceChargeback, setBalanceChargeback] = useState(null)
  const [balanceCollection, setBalanceCollection] = useState(null)

  useEffect(() => {
    const newBalance = window.localStorage.getItem(storageKey) || 0
    setBalance(newBalance)

    const newBalancePending =
      window.localStorage.getItem(storageKeyPending) || 0
    setbalancePending(newBalancePending)

    const newBalanceChargeback =
      window.localStorage.getItem(storageKeyChargeback) || 0
    setBalanceChargeback(newBalanceChargeback)

    const newBalanceCollection =
      window.localStorage.getItem(storageKeyCollection) || 0
    setBalanceCollection(newBalanceCollection)

    if (!isServer()) {
      const syncBalance = event => {
        const value = parseFloat(event.newValue)
        switch (event.key) {
          case storageKey:
            setBalance(value)
            break
          case storageKeyPending:
            setbalancePending(value)
            break
          case storageKeyChargeback:
            setBalanceChargeback(value)
            break
          case storageKeyCollection:
            setBalanceCollection(value)
            break
        }
      }
      window.addEventListener('storage', syncBalance)
    }
  }, [])

  const handleSetBalance = (
    newBalance,
    newBalancePending,
    newBalanceChargeback,
    newBalanceCollection
  ) => {
    newBalance = newBalance.toFixed(2)
    newBalancePending = newBalancePending.toFixed(2)

    setBalance(newBalance)
    window.localStorage.setItem(storageKey, newBalance)

    setbalancePending(newBalancePending)
    window.localStorage.setItem(storageKeyPending, newBalancePending)

    setBalanceChargeback(newBalanceChargeback)
    window.localStorage.setItem(storageKeyChargeback, newBalanceChargeback)

    setBalanceCollection(newBalanceCollection)
    window.localStorage.setItem(storageKeyCollection, newBalanceCollection)
  }

  return (
    <BalanceContext.Provider
      value={{
        balance,
        balancePending,
        balanceChargeback,
        balanceCollection,
        setBalance: handleSetBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}

export const useBalance = () => {
  return useContext(BalanceContext)
}
