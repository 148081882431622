import { createContext, useContext, useState } from 'react'

const EventListenerContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addListener: (eventName, listener) => undefined,
  removeListener: (eventName, listener) => undefined,
  notifyListeners: (eventName, args) => undefined,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const EventListenerProvider = ({ children }) => {
  const [listeners, setListeners] = useState({})

  const addListener = (eventName, listener) => {
    setListeners(listenersState => {
      const newListeners = {
        ...listenersState,
      }
      if (!newListeners[eventName]) {
        newListeners[eventName] = []
      }
      newListeners[eventName].push(listener)
      return newListeners
    })
  }

  const removeListener = (eventName, listener) => {
    setListeners(listenersState => {
      const newListeners = {
        ...listenersState,
      }
      if (newListeners[eventName]) {
        newListeners[eventName] = newListeners[eventName].filter(
          registeredListener => registeredListener !== listener
        )
      }
      return newListeners
    })
  }

  const notifyListeners = (eventName, args) => {
    if (listeners[eventName] && listeners[eventName].length > 0) {
      listeners[eventName].map(listenerFunction => listenerFunction(args))
    }
  }

  return (
    <EventListenerContext.Provider
      value={{
        addListener,
        removeListener,
        notifyListeners,
      }}
    >
      {children}
    </EventListenerContext.Provider>
  )
}

export const useEventListener = () => {
  return useContext(EventListenerContext)
}
