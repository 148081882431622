
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Router from 'next/router'
import '../App.scss'
import React, { useEffect } from 'react'
import HeadHome from '../components/HeadHome'
import NProgress from 'nprogress'
import { BalanceProvider } from '../contexts/balance'
import { AuthProvider } from '../contexts/auth'
import { LocalesProvider } from '../contexts/locales'
import { useApollo } from '../services/apolloClient'
import { ApolloProvider } from '@apollo/react-hooks'
import * as gtag from '../services/gtag'
import GoogleAnalytics from '../components/GoogleAnalytics'
import CookieConsentPopup from '../components/CookieConsentPopup'
import { UploadProvider } from '../contexts/upload'
import { SettingsProvider } from '../contexts/settings'
import { NotificationProvider } from '../contexts/notification'
import TagManager from 'react-gtm-module'
import { EventListenerProvider } from '../contexts/event-listener'
import 'react-image-lightbox/style.css'
import { SSRProvider } from 'react-bootstrap'

const MyApp = ({ Component, pageProps, err }) => {
  const apolloClient = useApollo(pageProps.initialApolloState)

  useEffect(() => {
    const handleRouteChange = url => gtag.pageview(url)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const startProgress = () => NProgress.start()
    const endProgress = () => NProgress.done()
    Router.events.on('routeChangeStart', startProgress)
    Router.events.on('routeChangeComplete', endProgress)
    Router.events.on('routeChangeError', endProgress)
    return () => {
      Router.events.off('routeChangeStart', startProgress)
      Router.events.off('routeChangeComplete', endProgress)
      Router.events.off('routeChangeError', endProgress)
    }
  }, [])

  // Google Tag Manager
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TZVFX94' })
  }, [])

  return (
    <SSRProvider>
      <HeadHome />
      <EventListenerProvider>
        <NotificationProvider>
          <AuthProvider>
            <LocalesProvider>
              <GoogleAnalytics />
              <ApolloProvider client={apolloClient}>
                <BalanceProvider>
                  <UploadProvider>
                    <SettingsProvider>
                      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                      <Component {...pageProps} err={err} />
                    </SettingsProvider>
                  </UploadProvider>
                </BalanceProvider>
              </ApolloProvider>
            </LocalesProvider>
          </AuthProvider>
        </NotificationProvider>
      </EventListenerProvider>
      <CookieConsentPopup />
    </SSRProvider>
  )
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  