import React from 'react'
import Head from 'next/head'
import { useTranslation } from '../../services/translation'
import { useRouter } from 'next/router'
import { structuredDataLogo } from '../../utils/json-logo'

function HeadHome() {
  const { t, lang } = useTranslation()
  const { asPath, locales } = useRouter()
  const url = `https://co3d.art${lang === 'en' ? '' : `/${lang}`}${
    asPath === '/' ? '' : `${asPath}`
  }`
  return (
    <>
      <Head>
        <title>{t('seo.title')}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link
          href='//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'
          rel='stylesheet'
        />
        <link
          rel='apple-touch-icon'
          sizes='57x57'
          href='https://co3d.art/favicons/apple-icon-57x57.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='60x60'
          href='https://co3d.art/favicons/apple-icon-60x60.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='72x72'
          href='https://co3d.art/favicons/apple-icon-72x72.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='76x76'
          href='https://co3d.art/favicons/apple-icon-76x76.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='114x114'
          href='https://co3d.art/favicons/apple-icon-114x114.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='120x120'
          href='https://co3d.art/favicons/apple-icon-120x120.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='144x144'
          href='https://co3d.art/favicons/apple-icon-144x144.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href='https://co3d.art/favicons/apple-icon-152x152.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='https://co3d.art/favicons/apple-icon-180x180.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='192x192'
          href='https://co3d.art/favicons/android-icon-192x192.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='https://co3d.art/favicons/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='96x96'
          href='https://co3d.art/favicons/favicon-96x96.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='https://co3d.art/favicons/favicon-16x16.png'
        />
        <link rel='manifest' href='https://co3d.art/favicons/manifest.json' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta
          name='msapplication-TileImage'
          content='https://co3d.art/favicons/ms-icon-144x144.png'
        />
        <meta name='theme-color' content='#ffffff' />
        <meta name='description' content={t('seo.desc')} />
        <meta property='og:type' content='website' />
        <meta property='og:title' key='og:title' content={t('seo.title')} />
        <meta
          property='og:description'
          key='og:description'
          content={t('seo.desc')}
        />
        <meta property='og:site_name' content='CO3D' />
        <meta property='og:url' content={url} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={t('seo.title')} />
        <meta name='twitter:description' content={t('seo.desc')} />
        <meta
          name='twitter:image'
          content='https://co3d.art/assets/img/co3d-facebook-share.jpg'
        />
        <meta
          property='og:image'
          key='og:image'
          content='https://co3d.art/assets/img/co3d-facebook-share.jpg'
        />
        <meta property='og:image:width' content='1080' />
        <meta property='og:image:height' content='1080' />
        <meta property='fb:app_id' content='523696041576385' />
        <meta
          name='facebook-domain-verification'
          content='kibtiun5dsyiyz7eyjknmamgr1bzbo'
        />
        <meta
          name='google-site-verification'
          content='D1Dlco9l_HdA_t5FPYFr8sduHzm3N9U2MHubOyc2Q0c'
        />
        <link rel='canonical' href={url} />
        {locales.map(locale => (
          <link
            key={locale}
            rel='alternate'
            hrefLang={locale}
            href={`https://co3d.art${locale === 'en' ? '' : `/${locale}`}${
              asPath === '/' ? '' : `${asPath}`
            }`}
          />
        ))}
        <script type='application/ld+json'>
          {structuredDataLogo(
            `https://co3d.art/${lang}`,
            'https://co3d.art/assets/img/co3d-facebook-share.jpg'
          )}
        </script>
      </Head>
    </>
  )
}

export default HeadHome
