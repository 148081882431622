import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://f88d488eacfd48a2a8fcf7066ae9b85e@o562255.ingest.sentry.io/5700547',
  tracesSampleRate: 1.0,
})
