import { gql } from 'apollo-boost'
import { initializeApollo } from './apolloClient'

const DO_LOGIN = gql`
  mutation login($email: String!, $password: String!, $remember: Boolean!) {
    login(email: $email, password: $password, remember: $remember) {
      accessToken
      expiresIn
      user {
        id
        name
        email
        emailConfirmed
        balance
        balancePending
        balanceChargeback
        balanceCollection
        picture
        currency
        country
        language
        slug
      }
    }
  }
`

export const createSession = async (email, password, remember) => {
  const apolloClient = initializeApollo()

  return apolloClient.mutate({
    mutation: DO_LOGIN,
    variables: {
      email,
      password,
      remember,
    },
  })
}

const DO_LOGIN_SOCIAL = gql`
  mutation loginSocial(
    $socialNetwork: String!
    $userId: String!
    $name: String!
    $email: String!
    $token: String!
    $currency: Currency!
  ) {
    loginSocial(
      socialNetwork: $socialNetwork
      userId: $userId
      name: $name
      email: $email
      token: $token
      currency: $currency
    ) {
      accessToken
      expiresIn
      user {
        id
        name
        email
        emailConfirmed
        balance
        balancePending
        balanceChargeback
        balanceCollection
        picture
        currency
        country
        language
        slug
      }
      firstLogin
    }
  }
`

export const createSocialSession = async (
  socialNetwork,
  userId,
  name,
  email,
  token,
  currency
) => {
  const apolloClient = initializeApollo()

  return apolloClient.mutate({
    mutation: DO_LOGIN_SOCIAL,
    variables: {
      socialNetwork,
      userId,
      name,
      email,
      token,
      currency,
    },
  })
}

const DO_LOGOUT = gql`
  mutation {
    clearSession
  }
`

export const clearSession = async () => {
  const apolloClient = initializeApollo()

  return apolloClient.mutate({
    mutation: DO_LOGOUT,
  })
}

export const rememberKey = '@CO3D/remember'

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    fetch(process.env.NEXT_STATIC_API_URL + '/refresh_token', {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        remember: window.localStorage.getItem(rememberKey),
      }),
    })
      .then(resolve)
      .catch(reject)
  })
}

export const isAuthenticated = (user, acceptUnconfirmed = false) => {
  if (!user) return false

  // If not is social login and not accept unconfirmed, then check if the user was confirmed email
  if (!user.socialLogin && !acceptUnconfirmed && !user.emailConfirmed)
    return false

  return true
}
