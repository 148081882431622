import * as Sentry from '@sentry/nextjs'

export const setLogUser = userId => {
  try {
    Sentry.setUser(userId ? { id: userId } : null)
  } catch (err) {
    console.error('Failed to set log user', err)
  }
}

export const captureException = err => {
  Sentry.captureException(err)
}

export const flush = async (timeout = 2000) => {
  await Sentry.flush(timeout)
}
