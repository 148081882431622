import 'firebase/messaging'
import firebase from 'firebase/app'
import localforage from 'localforage'

const fcmTokenKey = '@CO3D/fcm_token'

const tokenInlocalforage = async () => {
  return localforage.getItem(fcmTokenKey)
}

export const initFirebase = async function () {
  if (firebase.apps.length) return false

  const firebaseConfig = {
    apiKey: 'AIzaSyBBLWEuOXiT7e5Y0a7QvXlEy0FLuCEkqkY',
    authDomain: 'co3d-293712.firebaseapp.com',
    projectId: 'co3d-293712',
    storageBucket: 'co3d-293712.appspot.com',
    messagingSenderId: '871975644849',
    appId: '1:871975644849:web:c4c91dbe65afd63e847c90',
  }
  firebase.initializeApp(firebaseConfig)
}

export const hasUserToken = async function () {
  const token = await tokenInlocalforage()
  return token != null
}

export const requestPermission = async function () {
  if (firebase.messaging.isSupported()) {
    const token = await tokenInlocalforage()
    if (token !== null) {
      return token
    }

    const messaging = firebase.messaging()
    await messaging.requestPermission()
  }
}

export const getUserToken = async function () {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()
    const newToken = await messaging.getToken()
    await localforage.setItem(fcmTokenKey, newToken)
    return newToken
  }
}
