import { gql } from 'apollo-boost'
import { initializeApollo } from './apolloClient'

const DO_CHANGE_LANG = gql`
  mutation setLanguage($language: Language!, $currency: Currency!) {
    setLanguage(language: $language, currency: $currency)
  }
`

export const changelang = async (language, currency) => {
  const apolloClient = initializeApollo()

  return apolloClient.mutate({
    mutation: DO_CHANGE_LANG,
    variables: {
      language,
      currency,
    },
  })
}
