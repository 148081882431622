import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button'

const ModalConfirm2 = ({
  show,
  handleClose,
  size = 'xl',
  closeButton = true,
  title,
  children,
  buttons,
  className,
  fullscreen,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      className={className}
      fullscreen={fullscreen}
      size={size}
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {buttons && (
        <Modal.Footer>
          {buttons.map((button, i) => (
            <React.Fragment key={i}>
              {button.render ? (
                button.render()
              ) : (
                <Button
                  className={'btn-paralel ' + button.className}
                  label={button.label}
                  onClick={button.handleClick}
                  loading={button.loading}
                  loadingText={button.loadingText}
                  disabled={button.disabled}
                />
              )}
            </React.Fragment>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default ModalConfirm2
