import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'

const DO_CREATE_FILES = gql`
  mutation ($filesInput: [NewFileInput!]!) {
    createFile(filesInput: $filesInput) {
      uploadUrl
      file {
        id
        status
      }
    }
  }
`

const mapperToCreate = ({ purpose, file }) => ({
  filename: file.innerFile.name,
  purpose,
  crop: file.crop
    ? {
        left: file.crop.x,
        top: file.crop.y,
        width: file.crop.width,
        height: file.crop.height,
      }
    : undefined,
})

export const useCreateFile = function () {
  const [createFileMutation] = useMutation(DO_CREATE_FILES)
  const createFile = (unregisteredFiles, options) => {
    const finalOptions =
      options != null
        ? options
        : {
            variables: {
              filesInput: unregisteredFiles.map(mapperToCreate),
            },
          }
    return createFileMutation(finalOptions)
  }
  return {
    createFile,
  }
}

const GET_FILES = gql`
  query ($ids: [ID!]) {
    files(ids: $ids) {
      id
      status
    }
  }
`

export const useGetFiles = (ids, options) => {
  const result = useQuery(GET_FILES, {
    ...options,
    variables: {
      ids,
    },
  })
  return result
}
