module.exports = {
  locales: ['en', 'es', 'pt'],
  defaultLocale: 'en',
  pages: {
    '*': ['translation'],
    '/contest/tokusatsu': ['contest-tokusatsu'],
    '/contest/printandpaint': ['contest-printandpaint'],
    '/contest/hype2022': ['contest-hype2022'],
    '/terms': ['terms'],
    '/addcampaign': ['scales'],
    '/addrequest': ['scales'],
    '/addtomarketplace': ['scales'],
    '/m/[slug]': ['scales'],
    '/modeledit/[id]': ['scales'],
    'rgx:^/club': ['club'],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`./public/locales/${lang}/${ns}.json`).then(m => m.default),
}
