export const structuredDataLogo = (url, logo) => {
  let data = {
    '@context': 'http://schema.org/',
    '@type': 'Organization',
    url: `${url}`,
    logo: `${logo}`,
  }

  return JSON.stringify(data)
}
