import React, { useState } from 'react'
import ModalConfirm2 from '../ModalConfirm2'
import { Accordion, Alert } from 'react-bootstrap'
import Markdown from 'markdown-to-jsx'
import { useTranslation } from '../../services/translation'
import modalstyles from '../../css/modalnotifications.module.scss'

export const ModalNotificationPermission = ({
  show,
  handleClose,
  onAccept,
  loadingAccept,
  onRefuse,
  errorCode,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const buttons = [
    {
      label: t('notifications.modal.btnAccept'),
      handleClick: onAccept,
      disabled: loadingAccept !== 0,
      loading: loadingAccept !== 0,
      loadingText:
        loadingAccept === 1
          ? t('notifications.modal.enableNotifications')
          : t('notifications.modal.saving'),
    },
    {
      className: 'bt-secundario',
      label: t('notifications.modal.btnRefuse'),
      handleClick: onRefuse,
    },
  ]

  const handleOnSelect = active => {
    setIsOpen(!!active)
  }

  return (
    <ModalConfirm2
      show={show}
      handleClose={handleClose}
      title={t('notifications.modal.tit')}
      buttons={buttons}
      className={modalstyles.modalNotif}
    >
      <div className={modalstyles.modalNotifTit}>
        <img src='/assets/img/icon-modal-notif.png' />
        <div>
          <Markdown>{t('notifications.modal.txt1')}</Markdown>
        </div>
      </div>
      <Accordion defaultActiveKey='1' onSelect={handleOnSelect}>
        <Accordion.Item eventKey={1}>
          <Accordion.Body>
            <>
              <Markdown>{t('notifications.modal.txt2')}</Markdown>
              <Markdown>{t('notifications.modal.txt3')}</Markdown>
              <Markdown>{t('notifications.modal.txt4')}</Markdown>
            </>
          </Accordion.Body>
          <Accordion.Header>
            {t(`notifications.modal.see${isOpen ? 'Less' : 'More'}`)}
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
      {errorCode && (
        <Alert variant='danger'>
          {t(`notifications.modal.errors.${errorCode}`)}
        </Alert>
      )}
    </ModalConfirm2>
  )
}
