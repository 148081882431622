import React from 'react'
import { Button as ButtonBootstrap, Spinner } from 'react-bootstrap'
import { useTranslation } from '../../services/translation'

interface Props {
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const Button: React.FC<Props> = ({
  disabled,
  loading,
  loadingText,
  label,
  onClick,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBootstrap
      className={className || 'btn-paralel btn-full'}
      type='submit'
      disabled={disabled}
      onClick={onClick}
    >
      {loading ? (
        <span className='skew-fix'>
          {loadingText || t('botoes.carregando')}
          <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
          />
        </span>
      ) : (
        <span className='skew-fix'>{label}</span>
      )}
    </ButtonBootstrap>
  )
}

export default Button
