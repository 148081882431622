import useTranslationNext from 'next-translate/useTranslation'
import { _getCookie, _setCookie } from './cookie'

export const useTranslation = defaultNs => {
  return useTranslationNext(defaultNs || 'translation')
}

const cookieName = 'NEXT_LOCALE'

export const saveUserPreferredLocale = locale => {
  const fiveYears = 5 * 365
  _setCookie(cookieName, locale, fiveYears)
}

export const getUserPreferredLocale = () => {
  return _getCookie(cookieName)
}
