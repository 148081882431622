import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from '../services/translation'
import { useEventListener } from './event-listener'
import { useGetFiles } from '../services/files'

const UploadContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initQueue: files => undefined,
  clearQueue: () => undefined,
  filesQueue: {},
  // 0 -> sem upload, 1 -> enviando, 2 -> processando, 3 -> finalizado com erro, 4 -> finalizado com sucesso
  status: 0,
})

export const UploadProvider = ({ children }) => {
  const { t } = useTranslation()
  const [filesQueue, setFilesQueue] = useState({})
  const [status, setStatus] = useState(0)
  const { notifyListeners } = useEventListener()
  const { refetch: getFiles } = useGetFiles([], { skip: true })

  useEffect(() => {
    const handlerBeforeUnload = e => {
      const msg = t('uploadProgress.beforeClose')
      e.returnValue = msg
      return msg
    }

    if (status !== 0) {
      window.addEventListener('beforeunload', handlerBeforeUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', handlerBeforeUnload)
    }
  }, [status])

  const currentQueueSize = Object.keys(filesQueue).length
  useEffect(() => {
    const filesToUpload = Object.values(filesQueue).filter(
      file => file.progress === 0 && typeof file.status === 'undefined'
    )
    uploadFiles(filesToUpload)
  }, [currentQueueSize])

  const uploadFiles = files => {
    if (files.length) {
      files.forEach(async file => {
        try {
          await axios.put(file.url, file.file, {
            onUploadProgress: progress => {
              const { loaded, total } = progress
              const percentageProgress = Math.floor((loaded / total) * 100)
              setUploadProgress(file.id, percentageProgress)
            },
          })
          setFileUploadFinished(file.id, false)
        } catch (error) {
          setFileUploadFinished(file.id, true)
        }
      })
    }
  }

  const setUploadProgress = (id, progress) => {
    setFilesQueue(stateFilesQueue => ({
      ...stateFilesQueue,
      [id]: {
        ...stateFilesQueue[id],
        progress,
      },
    }))
  }

  const setFileUploadFinished = (id, error) => {
    setFilesQueue(stateFilesQueue => {
      const itemQueue = {
        ...stateFilesQueue[id],
        status: error ? 0 : 1,
        progress: error ? 0 : 100,
      }
      notifyListeners('fileUpdate', itemQueue)
      return {
        ...stateFilesQueue,
        [id]: itemQueue,
      }
    })
  }

  useEffect(() => {
    const filesQueueArray = Object.values(filesQueue)
    const processingFiles = filesQueueArray.filter(
      file => file.status === 1 && file.statusOnServer === 'PROCESSING'
    )
    const uploadingFiles = filesQueueArray.filter(
      file => typeof file.status === 'undefined'
    )
    if (processingFiles.length > 0 && uploadingFiles.length === 0) {
      const getProcessingFilesInfo = async () => {
        const ids = processingFiles.map(({ id }) => id)
        const { data } = await getFiles({
          ids,
        })
        const changedFiles = []
        setFilesQueue(stateFilesQueue => {
          let newFilesQueue = { ...stateFilesQueue }
          processingFiles.map(processingFile => {
            const file = data.files.find(file => file.id === processingFile.id)
            if (file && file.status !== processingFile.statusOnServer) {
              const itemQueue = {
                ...newFilesQueue[file.id],
                statusOnServer: file.status,
              }
              changedFiles.push(itemQueue)
              newFilesQueue = {
                ...newFilesQueue,
                [file.id]: itemQueue,
              }
            }
          })
          return newFilesQueue
        })
        changedFiles.map(file => notifyListeners('fileUpdate', file))
      }
      const interval = setInterval(getProcessingFilesInfo, 2000)
      return () => clearInterval(interval)
    }
  }, [filesQueue])

  useEffect(() => {
    const filesArray = Object.values(filesQueue)
    const finishedFiles = filesArray.filter(
      file =>
        file.status === 0 ||
        (file.status === 1 && file.statusOnServer !== 'PROCESSING')
    )
    if (filesArray.length > 0 && finishedFiles.length === filesArray.length) {
      const failedFile = filesArray.find(
        file => file.status === 0 || file.statusOnServer === 'FAILED'
      )
      const newStatus = failedFile ? 3 : 4
      setStatus(newStatus)
    }
  }, [filesQueue])

  useEffect(() => {
    const filesArray = Object.values(filesQueue)
    const pendingUploadFiles = filesArray.filter(
      file => typeof file.status === 'undefined'
    )
    if (pendingUploadFiles.length === 0 && status === 1) {
      setStatus(2)
    }
  }, [filesQueue])

  const initQueue = files => {
    if (status !== 0) {
      throw new Error('Upload in progress.')
    }

    let filesToEnqueue = {}
    files.map(({ file, uploadUrl, innerFile }) => {
      filesToEnqueue = {
        ...filesToEnqueue,
        [file.id]: {
          id: file.id,
          file: innerFile,
          url: uploadUrl,
          progress: 0,
          status: undefined,
          statusOnServer: file.status,
        },
      }
    })

    const newFilesQueue = {
      ...filesQueue,
      ...filesToEnqueue,
    }
    setFilesQueue(newFilesQueue)
    setStatus(1)
  }

  const clearQueue = () => {
    setFilesQueue({})
    setStatus(0)
  }

  return (
    <UploadContext.Provider
      value={{
        initQueue,
        clearQueue,
        filesQueue,
        status,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

export const useUpload = () => {
  return useContext(UploadContext)
}
