import React, { createContext, useContext, useState, useEffect } from 'react'
import { setAccessToken } from '../services/accessToken'
import {
  createSession,
  createSocialSession,
  clearSession,
  refreshToken,
  rememberKey,
} from '../services/auth'
import Router from 'next/router'
import { setUserId } from '../services/gtag'
import { useNotification } from './notification'
import { setLogUser } from '../services/logService'

const socialLoginKey = '@CO3D/socialLogin'

const AuthContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null,
  loadingUser: false,
  signIn: (email, password, remember) => undefined,
  signInSocial: (socialNetwork, userId, name, email, token, currency) =>
    undefined,
  signOut: () => undefined,
  refreshToken: () => undefined,
  updateUser: fieldsToUpdate => undefined,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const { getUserPermission } = useNotification()
  useEffect(() => {
    const loadUser = async () => {
      await doRefreshToken(true)
      setLoadingUser(false)
    }
    loadUser()
  }, [])

  const syncLogout = event => {
    if (event.key === 'logout' && !document.hasFocus()) {
      setUser(null)
      setAccessToken(null)
      setLogUser(null)
      Router.push('/')
    }
  }

  useEffect(() => {
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
      window.localStorage.removeItem('logout')
    }
  }, [])

  const setUserAndToken = (session, socialLogin = false) => {
    const newUser = {
      id: session.user.id,
      name: session.user.name,
      email: session.user.email,
      emailConfirmed: session.user.emailConfirmed,
      balance: session.user.balance,
      currency: session.user.currency,
      country: session.user.country,
      language: session.user.language,
      socialLogin,
      picture: session.user.picture,
      slug: session.user.slug,
    }
    setUser(newUser)

    const now = new Date()
    setAccessToken({
      token: session.accessToken,
      expiresIn: new Date(now.getTime() + session.expiresIn * 1000),
      refreshToken: session.refreshToken,
    })

    window.localStorage.setItem(socialLoginKey, socialLogin)

    setUserId(newUser.id)
    setLogUser(newUser.id)
  }

  const signIn = async (email, password, remember) => {
    const { data: session } = await createSession(email, password, remember)
    window.localStorage.setItem(rememberKey, remember)
    setUserAndToken(session.login)
    return session
  }

  const signInSocial = async (
    socialNetwork,
    userId,
    name,
    email,
    token,
    currency
  ) => {
    const { data: session } = await createSocialSession(
      socialNetwork,
      userId,
      name,
      email,
      token,
      currency
    )
    window.localStorage.setItem(rememberKey, true)
    setUserAndToken(session.loginSocial, true)
    return session
  }

  const signOut = async () => {
    await clearSession()
    setUser(null)
    setAccessToken(null)
    setLogUser(null)
    window.localStorage.removeItem(rememberKey)
    window.localStorage.removeItem(socialLoginKey)
    window.localStorage.setItem('logout', Date.now().toString())
  }

  const doRefreshToken = async (askNotification = false) => {
    try {
      const result = await refreshToken()
      if (result.status < 200 || result.status > 299) {
        setUser(null)
        setAccessToken(null)
      } else {
        const socialLogin =
          window.localStorage.getItem(socialLoginKey) === 'true'
        const resultAPI = await result.json()
        setUserAndToken(resultAPI, socialLogin)
        if (askNotification) {
          await getUserPermission()
        }
      }
    } catch (err) {
      setUser(null)
      setAccessToken(null)
    }
  }

  const updateUser = fieldsToUpdate => {
    setUser(user => ({
      ...user,
      ...fieldsToUpdate,
    }))
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingUser,
        signIn,
        signInSocial,
        signOut,
        refreshToken: doRefreshToken,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
