import React, { useState, useEffect } from 'react'
import { Toast } from 'react-bootstrap'
import Button from '../Button'
import { useTranslation } from '../../services/translation'
import { _getCookie, _setCookie } from '../../services/cookie'
import Link from 'next/link'
import styles from './style.module.scss'

const cookieName = 'co3dCookieConsent'
const cookieLifetime = 365

const CookieConsentPopup = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const isInAppBrowser = () => {
    const userAgent =
      typeof navigator !== 'undefined'
        ? navigator.userAgent || navigator.vendor || ''
        : ''
    return (
      userAgent.indexOf('FBAN') > -1 ||
      userAgent.indexOf('FBAV') > -1 ||
      userAgent.indexOf('Instagram') > -1
    )
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isInAppBrowser() && !_getCookie(cookieName)) {
        setShow(true)
      }
    }, 1000)
  }, [])

  const handleClick = () => {
    _setCookie(cookieName, 1, cookieLifetime)
    setShow(false)
  }

  return (
    <div className={styles.cookieConsetPopup + ' p-3'}>
      <Toast className='w-100' show={show}>
        <Toast.Body>
          <p className='ps-3'>
            {t('cookiePopup.txt')}{' '}
            <Link href='/terms'>
              <a target='_blank'>{t('cookiePopup.politica')}</a>
            </Link>
            .
          </p>
          <Button
            className='btn-paralel mx-3'
            label={t('cookiePopup.btn')}
            onClick={handleClick}
          />
        </Toast.Body>
      </Toast>
    </div>
  )
}

export default CookieConsentPopup
