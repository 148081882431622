import React, { createContext, useContext } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const SettingsContext = createContext({
  settings: {
    administrativeFee: 0,
    processingFee: 0,
    gatewayTransactionFee: 0,
    gatewayConversionFee: 0,
    gatewayFixedFee: 0,
    minWithdrawAmount: 0,
    dollarQuote: 0,
    maxModelAmount: 0,
    maxTotalQuotas: 0,
    minQuotaPrice: 0,
    maxQuotaPrice: 0,
    minTierPrice: 0,
    clubAdministrativeFee: 0,
  },
  loading: false,
  refetch: () => undefined,
})

const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      administrativeFee
      processingFee
      gatewayTransactionFee
      gatewayConversionFee
      gatewayFixedFee
      minWithdrawAmount
      dollarQuote
      maxModelAmount
      maxTotalQuotas
      minQuotaPrice
      maxQuotaPrice
      minTierPrice
      clubAdministrativeFee
    }
  }
`

export const SettingsProvider = ({ children }) => {
  const {
    data: settings,
    loading,
    refetch,
  } = useQuery(GET_SETTINGS, {
    fetchPolicy: 'network-only',
  })

  return (
    <SettingsContext.Provider
      value={{
        settings: settings ? settings.getSettings : {},
        loading,
        refetch,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  return useContext(SettingsContext)
}
